<template>
  <div
    style="flex: 0.3; display: flex; flex-direction: column; position: relative"
  >
    <div
      style="
        position: absolute;
        bottom: 0.55rem;
        left: 49%;
        display: flex;
        flex-direction: column;
        color: #fff;
        align-items: center;
        justify-content: center;
      "
    >
    </div>
    <div class="lab">
      <span style="height: 0.26rem; margin-left: 0.1rem">总投资额</span>
    </div>
    <div style="height: 0.1rem"></div>
    <dv-border-box-7
      :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']"
    >
      <div style="display: flex; padding: 0.1rem">
        <div>
          <div
            style="display: flex; align-items: center; margin-bottom: 0.05rem"
            v-for="(item, index) in labs1"
            :key="index"
          >
            <div
              style="width: 0.08rem; height: 0.08rem"
              :style="'background-color:' + item.color"
            ></div>
            <div style="margin-left: 0.1rem; color: #fff; font-size: 0.06rem">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div style="margin-left: auto">
          <div
            style="display: flex; align-items: center; margin-bottom: 0.05rem"
            v-for="(item, index) in labs2"
            :key="index"
          >
            <div
              style="width: 0.1rem; height: 0.1rem"
              :style="'background-color:' + item.color"
            ></div>
            <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>

      <div id="pie" style="width: 60%; height: 60%"></div>
    </dv-border-box-7>
    <div style="height: 0.1rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getRightTop} from "@/api/index.js";

export default {
  data() {
    return {
       date: [
            { value: 1048, name: "500万以下" },
            { value: 735, name: "500万到1500万" },
            { value: 580, name: "1500万到3000万" },
            { value: 484, name: "3000万到5000万" },
            { value: 300, name: "5000万到8000万" },
            { value: 300, name: "8000万以上" },
          ],
      labs1: [
        { color: "#EFA722", name: "500万以下" },
        { color: "#4B27BF", name: "500万到1500万" },
        { color: "#67D91F", name: "1500万到3000万" },
      ],
      labs2: [
        { color: "#EE0206", name: "3000万到5000万" },
        { color: "#C02BE6", name: "5000万到8000万" },
        { color: "#12E1ED", name: "8000万以上" },
      ],
      
    
    };
  },
  mounted() {
     getRightTop().then((res)=>{
       var num=res.data
       this.date=[
          { value: num.enterpriseInvestment, name: "500万以下" },
            { value: num.enterpriseInvestment1, name: "500万到1500万" },
            { value: num.enterpriseInvestment2, name: "1500万到3000万" },
            { value: num.enterpriseInvestment3, name: "3000万到5000万" },
            { value: num.enterpriseInvestment4, name: "5000万到8000万" },
            { value: num.enterpriseInvestment4, name: "8000万以上" },
       ]
       this.getCollegeList()
      })  

  }, 
   methods: {

    getCollegeList(){
          var chartDom = document.getElementById("pie");
    var myChart = echarts.init(chartDom);
    var option;
    var charPie3currentIndex = 0;
    option = {
      color: ["#EFA722", "#4B27BF", "#67D91F", "#EE0206", "#C02BE6", "#12E1ED"],
      tooltip: {
        show: true, //默认值true，可选为：true（显示） | false（隐藏）
        zlevel: 1, //默认值1，一级层叠控制。每一个不同的zlevel将产生一个独立的canvas，相同zlevel的组件或图标将在同一个canvas上渲染。zlevel越高越靠顶层，canvas对象增多会消耗更多的内存和性能，并不建议设置过多的zlevel，大部分情况可以通过二级层叠控制z实现层叠控制。
        z: 8, //默认值8，二级层叠控制，同一个canvas（相同zlevel）上z越高约靠顶层。
        showContent: true, //默认值true，tooltip主体内容显示策略，只需tooltip触发事件或显示axisPointer而不需要显示内容时可配置该项为false，
        trigger: "item", //默认值'item'，触发类型，默认数据触发，见下图，可选为：'item' | 'axis'
        position: [265, 40], //默认值null，位置指定，传入{Array}，如[x, y]， 固定位置[x, y]；传入{Function}，如function([x, y]) {return [newX,newY]}，默认显示坐标为输入参数，用户指定的新坐标为输出返回。
        extraCssText: "width:170px;height:80px;",
        showDelay: 20, //默认值20，显示延迟，添加显示延迟可以避免频繁切换，特别是在详情内容需要异步获取的场景，单位ms
        hideDelay: 100, //默认值100，隐藏延迟，单位ms
        transitionDuration: 0.4, //默认值0.4，动画变换时长，单位s，如果你希望tooltip的跟随实时响应，showDelay设置为0是关键，同时transitionDuration设0也会有交互体验上的差别。
        backgroundColor: 'rgba(5,6,92)',//默认值，提示背景颜色，默认为透明度为0.7的黑色
        borderRadius: 4, //默认值，提示边框圆角，单位px，默认为4
        borderWidth: 2, //默认值，提示边框线宽，单位px，默认为0（无边框）
        padding: 6, //默认值，提示内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距，同css
          lineStyle: {//默认值各异，
               color: 'rgb(255,255,255)',//默认值各异，颜色rgba
               type: 'solid',//默认值，
               width: 0,//默认值，
              shadowColor: 'rgba(0,0,0,0)',//默认值，折线主线(IE8+)有效，阴影色彩，支持rgba
               shadowBlur: 5,//默认值，折线主线(IE8+)有效，阴影模糊度，大于0有效
              shadowOffsetX: 3,//默认值，折线主线(IE8+)有效，阴影横向偏移，正值往右，负值往左
                 shadowOffsetY: 3,//默认值，折线主线(IE8+)有效，阴影纵向偏移，正值往下，负值往上
                },
                crossStyle: {//默认值，
                    color: 'rgba(0,0,0,0.7)',//默认值，
                    type: 'solid',//默认值，
                    width: 0,//默认值，
                    shadowColor: 'rgba(0,0,0,0)',//默认值，
                    shadowBlur: 5,//默认值，
                    shadowOffsetX: 3,//默认值，
                    shadowOffsetY: 3,//默认值，
                },
                shadowStyle: {//默认值，
                    color: 'rgba(0,0,0,0.7)',//默认值，
                    type: 'default',//默认值，
                },
                textStyle: {//默认值，
                  color: 'rgb(255,255,255)',
                    fontFamily: 'Arial, Verdana, sans...',//默认值，
                    fontSize: 14,//默认值，
                    fontStyle: 'normal',//默认值，
                    fontWeight: 'normal',//默认值，
                },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "详细内容:",
          type: "pie",
          radius: ["55%", "90%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              formatter: "{d}%",
              textStyle: {
                fontWeight: "normal",
                fontSize: 13,
                color: "#fff",
              },
            },
          },
          labelLine: {
            show: true,
            length: 0.01,
          },
          data: this.date
        },
      ],
    };

    myChart.setOption(option);

    // 3、自动高亮展示
    var chartHover = function () {
      var dataLen = option.series[0].data.length;
      // 取消之前高亮的图形
      myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: charPie3currentIndex,
      });
      charPie3currentIndex = (charPie3currentIndex + 1) % dataLen;
      // 高亮当前图形
      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: charPie3currentIndex,
      });
      // 显示 tooltip
      myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: charPie3currentIndex,
      });
    };
    myChart.startCharts = setInterval(chartHover, 2000);
    },
 
  }


};
</script>
<style>
.max {
  width: 0.5rem;
  position: absolute;
  top: -0.9rem;
  left: 0.1rem;
  
}
.baifenb {
  position: absolute;
  top: -0.7rem;
  left: 0.2rem;
}

</style>