<template>
  <div id="app">
    <dv-full-screen-container
      style="background-color: #06075e; display: flex; flex: 1; width: 100%"
    >
      <div
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0.05rem;
          flex-shrink: 0;
        "
      >
        <div style="height: 0.4rem"></div>
        <RightBottom></RightBottom>
        <!-- <LeftTop></LeftTop> -->
        <LeftBottom></LeftBottom>
      </div>
      <div
        style="
          flex: 1.8;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          padding: 0.2rem;
          position: relative;
        "
      >
        <div class="text-color1">泾河工业园信息平台</div>
        <div class="topbg">
          <img src="./assets/topbg.png" alt="" srcset="" />
        </div>
        <div class="topleftbg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div class="toprightbg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
     
        </div>

        <CenterTop></CenterTop>
        <CenterBottom></CenterBottom>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0.05rem;
          flex-shrink: 0;
          padding-right: 0.2rem;
        "
      >
        <div style="height: 0.4rem; flex-shrink: 0"></div>
        <LeftTop></LeftTop>
        <div style="height: 0.2rem; flex-shrink: 0"></div>
        <RightTop></RightTop>
        <div style="height: 0.1rem; flex-shrink: 0"></div>
        <RightCenter></RightCenter>
      </div>
    </dv-full-screen-container>
  </div>
</template>
<script>
import LeftTop from "@/components/LeftTop.vue";
import LeftBottom from "@/components/LeftBottom.vue";
import CenterTop from "@/components/CenterTop.vue";
import CenterBottom from "@/components/CenterBottom.vue";
import RightTop from "@/components/RightTop.vue";
import RightCenter from "@/components/RightCenter.vue";
import RightBottom from "@/components/RightBottom.vue";
export default {
  components: {
    LeftTop,
    LeftBottom,
    CenterTop,
    CenterBottom,
    RightTop,
    RightCenter,
    RightBottom,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0.28rem;
  width: 1920px !important;
}
.text-color1 {
  background: linear-gradient(
    360deg,
    rgba(43, 156, 248, 0.83),
    rgba(232, 254, 255, 0.83),
    rgba(232, 254, 255, 0.83)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  letter-spacing: 3px;
  height: 0.4rem;
  text-align: center;
  margin-left: -0.3rem;
}
.lab {
  /* width: 100%; */
  height: 0.36rem;
  background-image: url(@/assets/lab.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-left: 0.45rem;
}
.border1 {
  width: 49%;
  background-image: url(@/assets/border1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.1rem ;
}
span {
  background: linear-gradient(
    90deg,
    rgba(139, 199, 255, 0.6),
    rgba(232, 254, 255, 0.83),
    rgba(232, 254, 255, 0.83)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  height: 0.36rem;
  text-align: left;
  font-size: 0.18rem;
  font-weight: 700;
  font-style: oblique;
  padding-right: 0.4rem;
}
.text3 {
  background: linear-gradient(
    0deg,
    rgba(139, 199, 255, 0.6),
    rgba(210, 251, 253, 0.83),
    rgba(232, 254, 255, 0.83)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  text-align: left;
  font-size: 0.34rem;
  font-weight: 700;
  font-style: oblique;
}
.lab2 {
  width: 100%;
  height: 0.35rem;
  background-image: url(@/assets/lab.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
}
.topbg {
  position: absolute;
  top: 0;
  left: -65%;
}
.topleftbg {
  position: absolute;
  top: 0.3rem;
  left: -11%;
  width: 1.55rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  transform: skew(45deg,0deg);
}
.topleftbg>div{
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
 

}
.topleftbg>div:nth-child(1){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.35rem;
   animation: bgts 4s linear 0s infinite normal;

  
}
.topleftbg>div:nth-child(2){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.32rem;
    animation: bgts 4s linear 0.5s infinite normal;

  
}.topleftbg>div:nth-child(3){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.29rem;
    animation: bgts 4s linear 1s infinite normal;
  
}.topleftbg>div:nth-child(4){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.26rem;
    animation: bgts 4s linear 1.5s infinite normal;

  
}.topleftbg>div:nth-child(5){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.23rem;
    animation: bgts 4s linear 2s infinite normal;

  
}.topleftbg>div:nth-child(6){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.2rem;
    animation: bgts 4s linear 2.5s infinite normal;
  
}
.topleftbg>div:nth-child(7){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.17rem;
    animation: bgts 4s linear 3s infinite normal;
  
}
.topleftbg>div:nth-child(8){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.14rem;
    animation: bgts 4s linear 3.5s infinite normal;
  
}

.toprightbg {
  position: absolute;
  top: 0.3rem;
  left: 83%;
  width: 1.55rem;
  display: flex;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  transform: skew(-45deg,0deg);
}
.toprightbg>div{
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
 

}
.toprightbg>div:nth-child(1){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.35rem;
   animation: bgts 4s linear 0s infinite normal;

  
}
.toprightbg>div:nth-child(2){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.32rem;
    animation: bgts 4s linear 0.5s infinite normal;

  
}.toprightbg>div:nth-child(3){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.29rem;
    animation: bgts 4s linear 1s infinite normal;
  
}.toprightbg>div:nth-child(4){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.26rem;
    animation: bgts 4s linear 1.5s infinite normal;

  
}.toprightbg>div:nth-child(5){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.23rem;
    animation: bgts 4s linear 2s infinite normal;

  
}.toprightbg>div:nth-child(6){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.2rem;
    animation: bgts 4s linear 2.5s infinite normal;
  
}
.toprightbg>div:nth-child(7){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.17rem;
    animation: bgts 4s linear 3s infinite normal;
  
}
.toprightbg>div:nth-child(8){
  width: 0.1rem;
  background: rgba(104, 216, 254, 1);
  height: 0.14rem;
    animation: bgts 4s linear 3.5s infinite normal;
  
}

@keyframes bgts { 
  from {
   background: rgba(104, 216, 254, 1);

  }
 to {
    background: rgba(104, 216, 254, 0.1);


  }

}
</style>
