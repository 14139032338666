<template>
  <div
    style="flex: 0.3; display: flex; flex-direction: column; position: relative"
  >
    <div class="lab">
      <span style="height: 0.26rem; margin-left: 0.1rem">平台访问量</span>
    </div>
    <div style="height: 0.1rem"></div>
    <dv-border-box-7
      :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']"
    >
      <div style="display: flex; align-items: center; padding: 0.05rem 0.3rem">
        <div
          style="
            width: 0.1rem;
            height: 0.1rem;
            margin-left: auto;
            background-color: #3534ff;
          "
        ></div>
        <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
          访问量
        </div>
        <div
          style="
            width: 0.1rem;
            height: 0.1rem;
            margin-left: 0.1rem;
            background-color: #00fbd2;
          "
        ></div>
        <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
          企业用户
        </div>
        <div
          style="
            width: 0.1rem;
            height: 0.1rem;
            margin-left: 0.1rem;
            background-color: #1bc7ff;
          "
        ></div>
        <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
          访客
        </div>
      </div>
      <div id="line" style="width: 100%; height: 80%"></div>
    </dv-border-box-7>
    <div style="height: 0.1rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getvisit } from "@/api/index.js";

export default {
  data() {
    return {
      timer: null,
      list: [],
      date: [  "1月1日",
                "1月2日",
                "1月3日",
                "1月4日",
                "1月5日",
                "1月6日",
                "1月7日",],
      date1: [140, 232, 101, 264, 90, 340, 250],
      date2:  [120, 282, 111, 234, 220, 340, 310],
      date3: [320, 132, 201, 334, 190, 130, 220],
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    getvisit().then((res) => {
    var key=Object.keys(res)

   this.date1=[]
   this.date2=[]
   this.date3=[]
for (let i = 0; i < key.length; i++) {
     key[i]=  key[i].substring(5)
      
    }
    this.date=key
     for(let keys  in res){
       console.log(res[keys][0],"数据2");
               this.date1.push(res[keys][0])
               this.date2.push(res[keys][1])
               this.date3.push(res[keys][2])
        //  this.date2[i]=res.key[1]
        //  this.date3[i]=res.key[2]

        // console.log(res[keys],"778")
  }
    
      console.log(this.date1,"数据1");


    });
    this.show();
  },
  methods: {
    show() {
      this.timer = setInterval(() => {
        var chartDom = document.getElementById("line");
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            show: false,
          },
          grid: {
            left: "3%",
            right: "5%",
            bottom: "3%",
            top: "5%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: this.date,
              splitLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  //改变xy轴线条的颜色
                  color: "rgba(255, 255, 255, 0.3)",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFF", //更改坐标轴文字颜色
                  fontSize: 12, //更改坐标轴文字大小
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              splitNumber: 4,
              splitLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  //改变xy轴线条的颜色
                  color: "rgba(255, 255, 255, 0.3)",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFF", //更改坐标轴文字颜色
                  fontSize: 12, //更改坐标轴文字大小
                },
              },
            },
          ],
          series: [
            {
              name: "访问量",
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "RGBA(53, 52, 255, 0.4)",
                  },
                  {
                    offset: 1,
                    color: "RGBA(53, 52, 255, 0)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data:this.date1 ,
            },
            {
              name: "企业用户",
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "RGBA(53, 52, 255, 0.4)",
                  },
                  {
                    offset: 1,
                    color: "RGBA(53, 52, 255, 0)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data:this.date2,
            },
            {
              name: "访客",
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0.01,
                    color: "RGBA(53, 52, 255, 0.9)",
                  },
                  {
                    offset: 1,
                    color: "RGBA(53, 52, 255, 0)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data:this.date3,
            },
          ],
        };
        myChart.clear();
        myChart.setOption(option);
      }, 3000);
    },
  },
};
</script>