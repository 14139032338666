<template>
  <div style="flex: 0.4; display: flex; flex-direction: column">
    <div class="lab" slot="margin-bottom:0.2rem">
      <span>企业人才驿站</span>
    </div>
    <div style="flex: 1.3; position: relative" class="leftCountsboxs">
      <div class="leftCounts leftTopbg leftTop">
        <p>人才总数</p>
        <!-- <p>{{ staffDONums }}</p> -->
        <p>4410</p>
      </div>
      <div class="leftCounts leftTopbg rightTop">
        <!-- <p>{{ staffDO[1].staffType }}</p> -->
        <p>产业技能人才</p>
        <p>3674</p>
        <!-- <p>{{ staffDO[1].staffName }}</p> -->
      </div>
      <div class="leftCountss leftCenterbg countCenter">
        <p>共计人才</p>
      </div>
      <div class="leftFootbg leftCounts leftFoot">
        <p>经营管理人才</p>
        <p>736</p>
        <!-- <p>{{ staffDO[2].staffType }}</p> -->
        <!-- <p>{{ staffDO[2].staffName }}</p> -->
      </div>
      <div class="leftFootbg leftCounts rightFoot">
        <!-- <p>{{ staffDO[0].staffType }}</p> -->
        <!-- <p>{{ staffDO[0].staffName }}</p> -->
        <p>本科人才</p>
         <p>11088</p>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <div class="border1">
        <div style="font-size: 0.2rem; color: #50dbff; margin-top: 0.1rem">
          {{ enterpriseDO.enterpriseInvestment }}万元
        </div>
        <div style="
            display: flex;
            border: 0.5px solid #fff;
            border-radius: 4px;
            font-size: 0.08rem;
            width: 70%;
            margin: 0.1rem 0.1rem 0;
          ">
          <div style="
              display: flex;
              align-items: center;
              padding: 0.04rem 0.05rem;
              flex: 3;
              background-image: linear-gradient(90deg, #0066e5, #00b6ff);
              color: #fff;
              letter-spacing: 1px;
              justify-content: center;
            ">
            总投资金额
          </div>
          <div style="
              display: flex;
              align-items: center;
              padding: 0.04rem 0.05rem;
              flex: 1;
              color: #fff;
            ">
            100%
          </div>
        </div>
      </div>
      <div class="border1">
        <div style="font-size: 0.2rem; color: #50dbff; margin-top: 0.1rem">
          {{ enterpriseDO.enterpriseArea }}亩
        </div>
        <div style="
            display: flex;
            border: 0.5px solid #fff;
            border-radius: 4px;
            font-size: 0.08rem;
            width: 70%;
            margin: 0.1rem 0.1rem 0;
          ">
          <div style="
              display: flex;
              align-items: center;
              padding: 0.04rem 0.05rem;
              flex: 3;
              background-image: linear-gradient(90deg, #0066e5, #00b6ff);
              color: #fff;
              letter-spacing: 1px;
              justify-content: center;
            ">
            总占地面积
          </div>
          <div style="
              display: flex;
              align-items: center;
              padding: 0.04rem 0.05rem;
              flex: 1;
              color: #fff;
            ">
            100%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getselectbySum } from "@/api/index.js";

export default {
  data() {
    return {
      enterpriseDO: {
        enterpriseInvestment: 0,
        enterpriseArea: 0
      },//占地，投资
      staffDO: [{
        staffType: "A类",
        staffName: 0,
      }, {
        staffType: "B类",
        staffName: 0,
      }, {
        staffType: "C类",
        staffName: 0,
      }, {
        staffType: "D类",
        staffName: 0,
      }],//人才
      staffDONums: 0,//人才总数


    };
  },
  mounted() {
    getselectbySum().then((res) => {
      if (res.enterpriseDO.length == 0) {
        return
      }
      this.enterpriseDO = res.enterpriseDO

      this.staffDO = res.staffDO
      for (let i = 0; i < res.staffDO.length; i++) {
        this.staffDONums += parseInt(res.staffDO[i].staffName)

      }
      console.log(res, "投企业用地性质比例");
    })

  },
};
</script>

<style >
.leftCounts {
  position: absolute;
}

.leftTop {
  top: 2%;
  left: 15%;
  animation: hearts 2s linear 0s infinite normal;

}

.rightTop {
  top: 2%;
  left: 60%;
  animation: hearts 2s linear 0s infinite normal;

}

.leftFoot {
  top: 52%;
  left: 15%;
  animation: hearts 2s linear 1s infinite reverse;

}

.rightFoot {
  top: 52%;
  left: 60%;
  animation: hearts 2s linear 1s infinite reverse;

}

.countCenter {
  top: 27%;
  left: 37%;
}

.leftTopbg {
  width: 1.15rem;
  height: 1.41rem;
  background: url("../assets/left_top.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.leftCenterbg {
  width: 1.15rem;
  height: 1.41rem;
  background: url("../assets/left_center.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.leftFootbg {
  width: 1.15rem;
  height: 1.41rem;
  background: url("../assets/left_foot.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.leftCounts>p:nth-child(1) {
  /* width: 0.8rem; */
  margin: 0.35rem 0;
  text-align: center;
  font-size: 0.13rem;
  font-family: Adobe Heiti Std;
  font-weight: bolder;
  color: #fdf8f7;
}

.leftCounts>p:nth-child(2) {
  text-align: center;
  margin: -0.25rem 0rem;
  font-size: 0.22rem;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 3px 24px rgba(0, 30, 45, 0.53);
  -webkit-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
}

.leftCountss {
  position: absolute;
}

.leftCountss>p {
  margin: 0.4rem 0.4rem !important;
  width: 0.5rem;
  font-size: 0.19rem;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 3px 24px rgba(0, 30, 45, 0.53);
  -webkit-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
}

.leftCounts-time {
  padding: 0 0.2rem;
  background: linear-gradient(90deg,
      rgba(139, 199, 255, 0.6),
      rgba(232, 254, 255, 0.83),
      rgba(232, 254, 255, 0.83));
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  height: 0.36rem;
  text-align: left;
  font-size: 0.22rem;
  font-weight: 700;
  font-style: oblique;
  padding-right: 0.4rem;
}

.leftCounts-time>span {
  margin-left: 00.1rem;
  font-size: 00.22rem;
}

.leftCountsboxs {
  background: url("../assets/left_tops.png") no-repeat;
  background-size: 2rem 2.5rem;
  background-position: 1.25rem 0.3rem;
}

@keyframes hearts {
  0% {
    transform: translateX(0rem)
  }

  25% {

    transform: translatey(0.05rem)
  }

  50% {

    transform: translatey(0.1rem)
  }

  75% {

    transform: translatey(0.05rem)
  }

  100% {

    transform: translatey(0rem)
  }
}
</style>