import { render, staticRenderFns } from "./LeftBottom.vue?vue&type=template&id=2cca8167&scoped=true&"
import script from "./LeftBottom.vue?vue&type=script&lang=js&"
export * from "./LeftBottom.vue?vue&type=script&lang=js&"
import style0 from "./LeftBottom.vue?vue&type=style&index=0&id=2cca8167&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cca8167",
  null
  
)

export default component.exports