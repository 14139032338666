<template>
  <div style="flex: 1.2; display: flex; flex-direction: column">
    <!-- <div class="lab2">
      <span>企业产值</span>
    </div> -->
    <div class="top-info">
      <dv-border-Box-8 class="info-item" v-for="(item, i) in list" :key="i">
        <div class="item-top">
          <div :class="i.css" class="icon"></div>
          <div style="font-size: 0.14rem; color: #fff">{{ item.name }}</div>
        </div>
        <div class="imGs">
          <img :src="item.imgurl" style="" />
        </div>
        <div class="item-bottom">
          <div style="font-width: 700; font-size: 0.15rem">{{ item.val }}</div>
          <div style="font-width: 400; font-size: 0.1rem">万元</div>
          <div style="font-width: 400; font-size: 0.1rem; margin-left: auto">
            {{ item.num }}%
          </div>
          <img src="../assets/fangx/2.png" style="wdith: 0.15rem; height: 0.15rem; margin-left: 0.02rem"
            v-if="!item.show" />
          <img src="../assets/fangx/3.png" style="wdith: 0.15rem; height: 0.15rem; margin-left: 0.02rem" v-else />
        </div>
      </dv-border-Box-8>
    </div>

    <div class="lab2">
      <span>企业产值</span>
    </div>
    <div style="display: flex; align-items: center; padding: 0.1rem 0">
      <div style="
          width: 0.1rem;
          height: 0.1rem;
          margin-left: auto;
          background-color: RGBA(239, 167, 34, 1);
        "></div>
      <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
        企业税收
      </div>
      <div style="
          width: 0.1rem;
          height: 0.1rem;
          margin-left: 0.1rem;
          background-color: RGBA(3, 242, 195, 1);
        "></div>
      <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
        企业产值
      </div>
      <div style="
          width: 0.1rem;
          height: 0.1rem;
          margin-left: 0.1rem;
          background-color: RGBA(30, 192, 96, 1);
        "></div>
      <div style="margin-left: 0.1rem; color: #fff; font-size: 0.08rem">
        出口交货值
      </div>
    </div>
    <div style="flex: 1">
      <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']" style="width: 100%">
        <div id="barChar" style="width: 100%; height: 100%"></div>
      </dv-border-box-7>
    </div>
  </div>
</template>

<script >
import * as echarts from "echarts";
import { GetoutputValue, GetselectbyYearI } from "@/api/index.js";

export default {
  data() {
    return {
      list: [
        {
          name: "企业产值 ",
          val: "10310000",
          num: 0,
          show: false,
          css: "colors1",

          imgurl: require("../assets/fangx/blue.png"),
        },
        {
          name: "企业税收",
          val: "174560.12",
          num: 0,
          show: false,
          css: "colors2",

          imgurl: require("../assets/fangx/grin.png"),
        },
        {
          name: "出口交货值",
          val: "3363.014",
          num: 0,
          show: false,
          css: "colors3",
          imgurl: require("../assets/fangx/oring.png"),
        },
        {
          name: "工业销售产值总计",
          val: 9560000,
          num: 0,
          show: false,
          css: "colors4",
          imgurl: require("../assets/fangx/pink.png"),
        },
      ],
      timer: null,
      date: [
        ["product", "企业税收", "企业产值", "出口交货值"],
        ["2015", 43.3, 85.8, 93.7],
        ["2016", 83.1, 73.4, 55.1],
        ["2017", 86.4, 65.2, 82.5],
        ["2018", 72.4, 53.9, 39.1],
        ["2019", 72.4, 53.9, 39.1],
      ],
      arry: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    GetselectbyYearI().then((res) => {
      for (let keys in res) {
        this.arry.push(res[keys][0]);
      }
      if (this.arry[0] == undefined || this.arry[0] == undefined) {
        console.log(this.arry, "真题数据");
        return
      }

      var show1 = false;
      var show2 = false;
      var show3 = false;
      var show4 = false;
      if (this.arry[1]) {
        var num1 =
          (this.arry[1].revenueOutput - this.arry[0].revenueOutput) /
          this.arry[0].revenueOutput;
      }
      else {
        var num1 = 0
      }
      if (this.arry[1]) {
        var num2 =
          (this.arry[1].revenueTaxation - this.arry[0].revenueTaxation) /
          this.arry[0].revenueTaxation;
      }
      else {
        var num2 = 0
      } if (this.arry[1]) {
        var num3 =
          (this.arry[1].exportOutput - this.arry[0].exportOutput) /
          this.arry[0].exportOutput;
      }
      else {
        var num3 = 0
      }
      if (this.arry[1]) {
        var num4 =
          (this.arry[1].saleOutput - this.arry[0].saleOutput) /
          this.arry[0].saleOutput;
      }
      else {
        var num4 = 0
      }
      if (num1 < 0) {
        num1 = -num1;
        num1 = num1.toFixed(2);
        show1 = true;
      } else {
        num1 = num1.toFixed(2);
        show1 = false;
      }
      if (num2 < 0) {
        num2 = -num2;
        num2 = num2.toFixed(2);
        show2 = true;
      } else {
        num2 = num2.toFixed(2);
        show2 = false;
      }
      if (num3 < 0) {
        num3 = -num3;
        num3 = num3.toFixed(2);
        show3 = true;
      } else {
        num3 = num3.toFixed(2);
        show3 = false;
      }
      if (num4 < 0) {
        num4 = -num4;
        num4 = num4.toFixed(2);
        show4 = true;
      } else {
        num4 = num4.toFixed(2);
        show4 = false;
      }

      this.list = [
        {
          name: "企业产值 ",
          val: this.arry[0].revenueOutput ? this.arry[0].revenueOutput : 0,
          num: num1 ? num1 : 0,
          css: "colors1",
          show: show1,
          imgurl: require("../assets/fangx/blue.png"),
        },
        {
          name: "企业税收",
          val: this.arry[0].revenueTaxation ? this.arry[0].revenueTaxation : 0,
          num: num2 ? num2 : 0,
          css: "colors2",
          show: show2,
          imgurl: require("../assets/fangx/grin.png"),
        },
        {
          name: "出口交货值",
          val: this.arry[0].exportOutput ? this.arry[0].exportOutput : 0,
          num: num3 ? num3 : 0,
          show: show3,
          css: "colors3",
          imgurl: require("../assets/fangx/oring.png"),
        },
        {
          name: "工业销售产值总计",
          val: this.arry[0].saleOutput ? this.arry[0].saleOutput : 0,
          num: num4 ? num4 : 0,
          show: show4,
          css: "colors4",
          imgurl: require("../assets/fangx/pink.png"),
        },
      ];
    });
    GetoutputValue().then((res) => {
      for (let i = 0; i < res.length; i++) {
        this.date[i + 1] = [
          res[i].remark,
          parseInt(res[i].revenueTaxation),
          parseInt(res[i].revenueOutput),
          parseInt(res[i].exportOutput),
        ];
      }
      this.getChart();
    });
  },
  methods: {
    getChart() {
      var chartDom = document.getElementById("barChar");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        grid: {
          left: "8%",
          top: "12%",
          right: "5%",
          bottom: "10%",
        },
        legend: {
          show: false,
        },
        tooltip: {},
        dataset: {
          source: this.date,
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "rgba(255, 255, 255, 0.3)",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              //改变xy轴线条的颜色
              color: "rgba(255, 255, 255, 0.3)",
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFF", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
            },
          },
        },
        yAxis: {
          name: "(万元)",
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "rgba(255, 255, 255, 0.3)",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFF", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
            },
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            barWidth: 12,
            barGap: "100%",
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0],

                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FDD20A",
                  },
                  {
                    offset: 1,
                    color: "#0D0E58",
                  },
                ]),
              },
            },
          },
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 15, 15],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#02F4C5",
                  },
                  {
                    offset: 1,
                    color: "#0D0E58",
                  },
                ]),
              },
            },
          },
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 15, 15],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#08F185",
                  },
                  {
                    offset: 1,
                    color: "#0D0E58",
                  },
                ]),
              },
            },
          },
        ],
        func: (myChart, option) => {
          let start = 0,
            end = 7;
          option.dataZoom.startValue = option.xAxis[0].data[start]; //
          option.dataZoom.endValue = option.xAxis[0].data[end];
          myChart.setOption(option);
          if (this.electInterval) {
            clearInterval(this.electInterval);
          }
          this.electInterval = setInterval(function () {
            option.dataZoom.startValue = option.xAxis[0].data[start];
            option.dataZoom.endValue = option.xAxis[0].data[end];
            myChart.setOption(option);
            start += 1;
            end += 1;
            if (end >= option.xAxis[0].data.length) {
              end = 7;
              start = 0;
            }
          }, 5000);
        },
      };
      // myChart.func(myChart, option)
      option && myChart.setOption(option);

      // 3、自动高亮展示
    },
  },
};
</script>

<style>
.top-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.1rem;
  width: 100%;
  height: 1.5rem;
  margin-top: -0.43rem;
}

.top-info .info-item {
  width: 23%;
  height: 1rem;
  /* border: 1px solid #00B4FF; */
  border-radius: 4px;
  background: #05065c;
  position: relative;
  padding: 0.05rem;
  margin-top: 0.2rem;
}

.nfo-item-box {
  width: 25%;
  height: 1rem;
}

.top-info .info-item .item-top {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}

.top-info .info-item .item-top .icon {
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  /* background: #00B4FF; */
  margin-right: 0.05rem;
  margin-left: 0.05rem;
}

.top-info .info-item .item-bottom {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  color: #fff;
  margin-left: 0.05rem;
}

.colors1 {
  background: #00b4ff;
}

.colors2 {
  background: rgb(126, 45, 255);
}

.colors3 {
  background: rgb(255, 179, 55);
}

.colors4 {
  background: rgb(255, 117, 226);
}

.imGs {
  position: absolute;
  top: 0rem;
  right: 0.1rem;
}

.imGs>img {
  width: 0.7rem;
  /* height:0.rem; */
}
</style>