<template>
  <div
    style="flex: 1; display: flex; flex-direction: column; position: relative"
  >
    <div class="wrerTime">
      <div class="leftCounts-time">{{ datetime | timeDate }}</div>
      <div class="rightTopWenter">{{getweather.temperature}}℃  {{getweather.weather}} {{getweather.windpower}}级{{getweather.winddirection}}风</div>
    </div>
    <div class="lab labss">
      <span style="height: 0.26rem; margin-left: 0.1rem">招商引资</span>
    </div>
    <div style="height: 0.1rem"></div>
    <dv-border-box-7
      :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']"
    >
      <div class="videos">
        <video
          :src="video1 | getSex"
          autoplay
          loop
          controls
          v-if="show"
        ></video>
        <img :src="video1 | getSex" alt width="540" height="230px" v-else/>
      </div>
    </dv-border-box-7>
    <dv-border-box-7
      :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']"
    >
      <div class="eftBox_img">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, k) in imgList" :key="k">
              <div class="eftBox_img_s" @click="add(k)"><img :src="item | getSex" alt /></div>
            </div>
              <!-- <div class="swiper-slide">
              <div class="eftBox_img_s"><img src="../assets/2.png" alt /></div>
            </div> -->
          </div>
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import { getJournaPage,Getgetweather } from "@/api/index.js";

export default {
  data() {
    return {
      datetime: null,
      config: {},
      video1:"",
      video2:[],
      imgList: [
        
      ],
      show:true,
      getweather:{},
    };
  },
  mounted() {
       Getgetweather({ }).then((res) => {
      this.getweather={
        humidity:res.lives[0].humidity,
        temperature:res.lives[0].temperature,
        weather:res.lives[0].weather,
        windpower:res.lives[0].windpower,
        winddirection:res.lives[0].winddirection,

      }

   
    });
    getJournaPage({
        pageNo: 1,
        pageSize: 5,
        journaClassify: "6",
        status: "4",
    }).then((res) => {
      var list=res.data.list
      for (let i = 0; i < list.length; i++) {
        if (list[i].journaPic !=null) {
            this.imgList.push(list[i].journaPic)
            this.video2.push(list[i].journaUrl)
        }
      
      
        
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].journaUrl!=null) {
          this.video1=list[i].journaUrl;
          break
        }
      }

   
    });


    new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: 2000,
      loop: true, // 循环模式选项
      slidesPerView: 2,
      loopPreventsSlide: true,
      observer: true,
      observeParents: false, //修改swiper的父元素时，自动初始化swiper
      onSlideChangeEnd: function (swiper) {
        //更新Swiper，这个方法包含了updateContainerSize，updateSlidesSize，updateProgress，updatePagination，updateClasses方法。也就是数据改变是重新初始化一次swiper；
        swiper.update();
        //重新对需要循环的slide个数进行计算，当你改变了slidesPerView参数时需要用到，需要自动轮播的时候必须要加上；
        swiper.reLoop();
        //重新开始自动切换；
        swiper.startAutoplay();
      },
    });
    setInterval(() => {
      var date = new Date();
      this.datetime = date;
    }, 1000);
  },
  methods:{
    add(i){
      if (this.video2[i]!=null) {
        this.video1=this.video2[i]
        this.show=true
      }else{
        this.video1=this.imgList[i]
        this.show=false
      }
    }

  },

  filters: {
 getSex(value){
  return  'https://api.jhgyy.cn/'+value
},
  timeDate(value) {
      var date = new Date(value);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
            var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
            let strDate = M+D+h+m+s;
            return strDate;

    },
  },
};
</script>
<style>
.videos {
  overflow: hidden;
  /* width: ; */
  margin-top: 0.1rem;
}
.labss {
  margin-top: 0.1rem;
}
.videos > video {
  margin-top: -0.31rem;
  width: 5.3rem;
  /* height: 4rem; */
  margin-left: 00.1rem;
}
.wrerTime {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightTopWenter {
  /* text-align: right; */
  height: 0.2rem;
  padding: 0.1rem 0.1rem;
  font-size: 0.21rem;
  font-family: Rajdhani;
  font-weight: 500;
  color: #ffffff;
  line-height: 12px;
  text-shadow: 0px 6px 5px rgba(0, 252, 255, 0.18);
  background: linear-gradient(0deg, #1eaff2 0%, #82e0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.leftCounts-time {
  padding: 0 0.2rem;
  background: linear-gradient(
    90deg,
    rgba(139, 199, 255, 0.6),
    rgba(232, 254, 255, 0.83),
    rgba(232, 254, 255, 0.83)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  height: 0.36rem;
  text-align: left;
  font-size: 0.22rem;
  font-weight: 700;
  font-style: oblique;
}
.eftBox_img {
  width: 5.3rem;
  padding: 0.1rem 0;
  margin-top: 00.1rem;
  margin-left: 00.1rem;
}
.eftBox_img_s img {
  width: 2.5rem;
  height: 2rem;
}
.eftBox_img_s{
  /* padding:0 0.4rem; */
}
.swiper-slide{
  padding: 0 0.01rem;
}
</style>