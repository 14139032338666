<template>
  <div
    style="
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    "
  >
    <div style="position: relative">
      <img
        src="@/assets/bg.png"
        style="width: 100%; height: 3.4rem; width: 8.36rem"
      />
      <div class="crnterTopTitle crnterTopTitle_leftTop">
        <p @click="getTanenFun('规上企业')">
          <!-- 规上企业<span>{{ gauge }} 家</span> -->
          规上企业<span>132 家</span>
        </p>
      </div>
      <div class="crnterTopTitle crnterTopTitle_leftFoot">
        <p @click="getTanenFun('招商引资')">
          招商引资<span>375 家</span>
          <!-- 招商引资<span>{{ lease }} 家</span> -->
        </p>
      </div>
      <div class="crnterTopTitle crnterTopTitle_rightTop">
        <p @click="getTanenFun('规下企业')">
          规下企业<span>749 家</span>
          <!-- 规下企业<span>{{ regulation }} 家</span> -->
        </p>
        <!-- <p>规下企业<span>751 家</span></p> -->
      </div>
      <div class="crnterTopTitle crnterTopTitle_rightFoot">
        <p @click="getTanenFun('租赁企业')">
          <!-- 租赁企业<span>{{ acquisition }} 家</span> -->
          租赁企业<span>506 家</span>
        </p>
        <!-- <p>征地企业<span>375家</span></p> -->
      </div>
      <div class="crnterTopWai">
        <img
          src="@/assets/wai.png"
          style="width: 100%; height: 3.4rem; width: 3.36rem"
        />
      </div>
      <div class="crnterTopli">
        <img
          src="@/assets/li.png"
          style="width: 100%; height: 2.8rem; width: 2.8rem"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="50%"
      append-to-body
      style="font-size: 20px"
    >
      <el-table :data="tableData" stripe style="width: 100%" align="center">
        <el-table-column prop="name" label="企业名" width="400">
        </el-table-column>
        <el-table-column prop="tenantType" label="企业类型">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style=" text-align: center; margin-top: 20px;"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import { Getcenter, getTanen } from "@/api/index.js";

export default {
  components: {},
  data() {
    return {
      acquisition: undefined,
      gauge: undefined,
      lease: undefined,
      regulation: undefined,
      open: false,
      title: "提示",
      tableData: [],
      page: 1,
      total:1,
      strDate: "",
      tenantOtherDate: "",
    };
  },
  watch: {},
  created() {
    this.getCollegeList();
    // this.getTanenFun();
  },
  methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
      this.page=val
      this.getTanenFun()
      },
    getCollegeList() {
      Getcenter().then((res) => {
        this.acquisition = res.data[3].value;
        this.gauge = res.data[0].value;
        this.lease = res.data[4].value;
        this.regulation = res.data[1].value;
      });
    },
    getTanenFun(data) {
      if (data) {
        this.page=1
        if (data.indexOf("规上") != -1) {
        this.strDate = "规上";
        this.title = "规上企业";
      }
      if (data.indexOf("规下") != -1) {
        this.strDate = "规下";
        this.title = "规下企业";
      }
      if (data.indexOf("招商引资") != -1) {
        this.strDate = "招商引资";
        this.title = "招商引资企业";
      }
      if (data.indexOf("租赁企业") != -1) {
        this.strDate = "租赁企业";
        this.title = "租赁企业";
      }
      }
      
      var form = {
        pageNo: this.page,
        pageSize: 10,
        tenantType: this.strDate,
      };
      getTanen(form).then((res) => {
        this.tableData = res.data.list;
        this.total=res.data.total
        this.open = true;
      });
    },
  },
};
</script>
<style scoped>
.crnterTopTitle {
  position: absolute;
}
.crnterTopTitle_leftTop {
  top: 0.48rem;
  left: 0.3rem;
}
.crnterTopTitle_leftFoot {
  top: 2.1rem;
  left: 0.3rem;
}
.crnterTopTitle > p {
  font-size: 0.22rem !important;
  color: #fff;
}
.crnterTopTitle > p > span {
  color: #fff;
  padding-right: 0.15rem !important;
  padding-left: 0.15rem !important;
  font-size: 0.28rem !important;
}
.crnterTopTitle_rightTop {
  top: 0.59rem;
  right: 0.4rem;
}
.crnterTopTitle_rightFoot {
  top: 2.08rem;
  right: 0.35rem;
}
.crnterTopTitle_rightTop > p > span {
  padding-right: 0 !important;
  padding-left: 0.05rem !important;
}
.crnterTopTitle_rightFoot > p > span {
  padding-right: 0 !important;
  padding-left: 0.15rem !important;
}

.crnterTopWai {
  position: absolute;
  top: 0;
  left: 2.5rem;
  animation: heart 5s linear 0s infinite normal;
}
.crnterTopli {
  position: absolute;
  top: 0.35rem;
  left: 2.8rem;
  /* display: none; */
  animation: heart 5s linear 0s infinite reverse;

}
@keyframes heart {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
