<template>
  <div style="flex: 1; display: flex; flex-direction: column">
    <div class="lab" style="margin-top: 0.2rem">
      <span>行业类型</span>
    </div>
    <div style="padding: 0.1rem 0.05rem; flex: 1; overflow: hidden">
      <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']">
        <div class="leftBottom_box">
          <div style="
              display: flex;
              align-items: center;
              padding: 0.1rem;
              flex-direction: column;
            " class="leftBottom_scale">
            <div class="leftBottom_scaless">
              <div class="text3" style="margin-top: 0rem">
                <!-- {{ list[0].contactName }} -->
                651
              </div>
              <div style="font-size: 0.14rem; color: #fff; letter-spacing: 3px">
                {{ list[0].tenantType }}
              </div>
              <img src="@/assets/aa.png" style="width: 1.6rem; height: 1.3rem; margin-top: -0.5rem" />
            </div>
          </div>
          <div style="
              display: flex;
              align-items: center;
              padding: 0.1rem;
              flex-direction: column;
            ">
            <div class="leftBottom_centersss">
              <!-- <div class="text3" style="margin-top: 0rem">{{ nums }}</div> -->
              <div class="text3" style="margin-top: 0rem">881</div>
              <div style="font-size: 0.14rem; color: #fff; letter-spacing: 3px">
                总体数据
              </div>
              <img src="@/assets/aa.png" style="width: 1.6rem; height: 1.3rem; margin-top: -0.5rem" />
            </div>
          </div>
          <div style="
              display: flex;
              align-items: center;
              padding: 0.1rem;
              flex-direction: column;
            " class="leftBottom_scale">
            <div class="leftBottom_scaless">
              <div class="text3" style="margin-top: 0rem">
                <!-- {{ list[1].contactName }} -->
                136
              </div>
              <div style="font-size: 0.14rem; color: #fff; letter-spacing: 3px">
                {{ list[1].tenantType }}
              </div>
              <img src="@/assets/aa.png" style="width: 1.6rem; height: 1.3rem; margin-top: -0.5rem" />
            </div>
          </div>
        </div>
      </dv-border-box-7>
    </div>
    <div style="
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0.25rem;
        justify-content: space-between;
      ">
      <div style="
          display: flex;
          justify-content: space-between;
          width: 47% !important;
          height: 50%;
          padding: 0.04rem;
        ">
        <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']" style="width: 100%">
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            ">
            <img src="@/assets/bb (1).png" style="width: 0.38rem; margin-right: 0.1rem" />
            <div style="display: flex; flex-direction: column">
              <div class="text3" style="font-size: 0.2rem; text-align: center">
                <!-- {{ item.contactName }} -->
                23
              </div>
              <div style="font-size: 0.07rem; color: #fff; letter-spacing: 3px">
                <!-- {{ item.tenantType }} -->
                石油装备制造
              </div>
            </div>
          </div>
        </dv-border-box-7>

      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          width: 47% !important;
          height: 50%;
          padding: 0.04rem;
        ">
        <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']" style="width: 100%">
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            ">
            <img src="@/assets/bb (1).png" style="width: 0.38rem; margin-right: 0.1rem" />
            <div style="display: flex; flex-direction: column">
              <div class="text3" style="font-size: 0.2rem; text-align: center">
                <!-- {{ item.contactName }} -->
                23
              </div>
              <div style="font-size: 0.07rem; color: #fff; letter-spacing: 3px">
                <!-- {{ item.tenantType }} -->
                医药代工
              </div>
            </div>
          </div>
        </dv-border-box-7>
      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          width: 47% !important;
          height: 50%;
          padding: 0.04rem;
        ">
        <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']" style="width: 100%">
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            ">
            <img src="@/assets/bb (1).png" style="width: 0.38rem; margin-right: 0.1rem" />
            <div style="display: flex; flex-direction: column">
              <div class="text3" style="font-size: 0.2rem; text-align: center">
                <!-- {{ item.contactName }} -->
                23
              </div>
              <div style="font-size: 0.07rem; color: #fff; letter-spacing: 3px">
                <!-- {{ item.tenantType }} -->
                电子设备
              </div>
            </div>
          </div>
        </dv-border-box-7>
      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          width: 47% !important;
          height: 50%;
          padding: 0.04rem;
        ">
        <dv-border-box-7 :color="['rgba(33, 189, 224, 0.2)', 'rgba(33, 189, 224, 1)']" style="width: 100%">
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            ">
            <img src="@/assets/bb (1).png" style="width: 0.38rem; margin-right: 0.1rem" />
            <div style="display: flex; flex-direction: column">
              <div class="text3" style="font-size: 0.2rem; text-align: center">
                <!-- {{ item.contactName }} -->
                23
              </div>
              <div style="font-size: 0.07rem; color: #fff; letter-spacing: 3px">
                <!-- {{ item.tenantType }} -->
                电子设备
              </div>
            </div>
          </div>
        </dv-border-box-7>
      </div>
    </div>
  </div>
</template>
<script>
import { GetselectbyType } from "@/api/index.js";

export default {
  data() {
    return {
      list: [],
      lists: [],
      nums: null,
    };
  },
  mounted() {
    GetselectbyType().then((res) => {
      this.list = res;
      this.lists = res.slice(2, 6);
      for (let i = 0; i < res.length; i++) {
        this.nums += parseInt(res[i].contactName)
      }
    });
  },
};
</script>
<style scoped>
.leftBottom_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.leftBottom_scale {
  transform: scale(0.7);
  margin-top: 0.2rem;
}

.leftBottom_scaless {
  text-align: center;
  animation: hearts 3s linear 0s infinite reverse;
}

.leftBottom_centersss {
  text-align: center;

  animation: hearts 3s linear 1s infinite reverse;
}

@keyframes hearts {
  0% {
    transform: translateX(0rem);
  }

  25% {
    transform: translateX(0.1rem);
    transform: translatey(0.1rem);
  }

  50% {
    transform: translateX(0.2rem);
    transform: translatey(0.2rem);
  }

  75% {
    transform: translateX(0.1rem);
    transform: translatey(0.1rem);
  }

  100% {
    transform: translateX(0rem);
    transform: translatey(0rem);
  }
}
</style>