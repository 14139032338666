
import {Service} from '@/util/request'
//获取企业详情
export function getTanen(data) {
    return Service({
        url:'/system/screen/getTanentExcle',
        method: 'get',
        params: data
    })
}
//大屏中间
export function Getcenter() {
    return Service({
        url:'/system/screen/getTanentType',
        method: 'get',
    })
}
//大屏中间2
export function GetselectbyYearI() {
    return Service({
        url:'system/screen/selectbyYearI',
        method: 'get',
    })
}
//投资额
export function getRightTop() {
    return Service({
        url:'system/screen/selectbyInvestment',
        method: 'get',
    })
}
//投企业用地性质比例
export function getselectbySum() {
    return Service({
        url:'system/screen/selectbySum',
        method: 'get',
    })
}
//平台访问量
export function getvisit() {
    return Service({
        url:'system/screen/visit',
        method: 'get',
    })
}
//行业类别
export function GetselectbyType() {
    return Service({
        url:'system/screen/selectbyType',
        method: 'get',
    })
}
//天气
export function Getgetweather() {
    return Service({
        url:'getweather/get',
        method: 'get',
    })
}
//企业产值
export function GetoutputValue() {
    return Service({
        url:'system/screen/outputValue',
        method: 'get',
    })
}
// 获得招商引资分页
export function getJournaPage(query) {
    return Service({
      url: 'system/journa/pagebyadmin',
      method: 'get',
      params: query
    })
  }
  